import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Card from "../shared_components/gatsby-card"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`query {
      site {
        siteMetadata {
          author
        }
      }
    }`
  )

  return (
  <Card oneliner={true}>
    <span style={{flex: '1'}}>
      &copy; {new Date().getFullYear()} {site.siteMetadata.author}
    </span>
    <span style={{flex: '1', textAlign: 'right', fontSize: '22px'}}>
      <a href="https://twitter.com/jasonbcox0" style={{ textDecoration: 'none', color: 'black' }}>
        <i style={{margin: '0 4px'}} class="fab fa-twitter"></i>
      </a>
    </span>
  </Card>
  )
}

export default Footer
